import React from 'react';
import { useTheme } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import Main from 'layouts/Main';
import Oly from './Oly';
import Oly21 from './Oly21';
import Oly22 from './Oly22';

import Container from 'components/Container';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Oly23 from './Oly23';
import Scholastic from './Scholastic';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

// const mock = [
//   {
//     name: 'Arnav Aithal',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1A',
//     status: '1',
//     role: 'Admin',
//   },
//   {
//     name: 'Dhrupad H',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1C',
//     status: '1',
//     role: 'Owner',
//   },
//   {
//     name: 'Aashvi Bhat',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1C',
//     status: '1',
//     role: 'Member',
//   },
//   {
//     name: 'Avyukth Kiran Kashyap',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1C',
//     status: '1',
//     role: 'Admin',
//   },
//   {
//     name: 'Mysha Mishra',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1D',
//     status: '1',
//     role: 'Owner',
//   },
//   {
//     name: 'Arjun K',
//     email: 'Medal of Excellence-Gold (Olympiad Topper)',
//     title: 'Class 1E',
//     status: '1',
//     role: 'Admin',
//   },
// ];

const Olympiad = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container>
        {/* <Scholastic/> */}
      <Oly23 />
        <Oly22 />
        <Oly/>
        <Oly21 />
      </Container>
      <Container>
       
      </Container>
   
    </Main>
  );
};

export default Olympiad;
